/* 共通するCSSはここで定義 */

table {
  border-collapse: collapse;
  margin: 1em 0;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f5f5f5;
}

.sectionLine {
  display: flex;
  flex-wrap: wrap;
  gap: 1;
  border-bottom: 1px solid #ddd;
}

.articleTitle {
  font-size: 1.3rem !important;
  position: relative;
  margin-bottom: 0.5rem;
}

.articleTitle::before {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 10px;
  transform: skew(-45deg);
  background-image: repeating-linear-gradient(
    90deg,
    transparent 0 4px,
    #a0d8ef 4px 8px
  );
  content: "";
  z-index: -1;
}

.articleCard {
  width: 250px;
  height: 190px;
  margin-bottom: 16px;
  /* Cardコンポーネントで上書きされるためimportantを活用 */
  border-radius: 16px !important;
  transition: transform 0.2s ease-in-out !important;
}

.articleCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

/* Material UIコンポーネントの
   上書き設定が必要な箇所はここで定義 */

/* Header */
.muiToolBar {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

/* root container*/
.rootContainer {
  padding-left: 48px !important;
  padding-right: 48px !important;
}
